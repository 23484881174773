import model from './mobileModal'
import axios from 'axios'
import { isDevice } from "@/utils/utils";
import { getStore, } from "@/utils/store";

export const handleJson = (type,params)=>{
  return type == 1? JSON.stringify(params) : JSON.parse(params);
}

// 获取埋点上报接口
export const getTrackUrl =() => {
  return new Promise((resolve, reject) => {
    axios.get(window.SITE_CONFIG['apiURL'] + '/api/buriedPointConfig')
      .then(({ data: res }) => {
          if(res.code == 0){
              resolve(res.data.apiUrl)
          }else {
              resolve('')
          }
      })
  });
}

export const getVisitorId = ()=>{
  return new Promise((resolve, reject) => {
    if(sessionStorage.getItem('iotVisitorId')){
      resolve(sessionStorage.getItem('iotVisitorId'))
    }else {
      const fpPromise = import('@fingerprintjs/fingerprintjs')
      .then(FingerprintJS => FingerprintJS.load())
      fpPromise
      .then(fp => fp.get())
      .then(result => {
        const visitorId = result.visitorId
        sessionStorage.setItem('iotVisitorId',visitorId)
        resolve(visitorId);
      })
    }
  });
}

export const handleToLowerCase = (str)=>{
  let reg = new RegExp('[A-Z]','g');
  let newstr = str.replace(reg,function(match,offset,ss){ //match 匹配到的字符，offset 偏移量-下标，ss 原字符
    return match.toLowerCase()
  })
  return newstr;
}

export const handleTrackParams = (data = null)=>{
  let userInfo = getStore({name: 'userInfo'}) || {}
	let userOpenId = getStore({ name: "userOpenId" }) || {} //用户的OpenId;
  let connectionObj = navigator.connection || navigator.mozConnection || navigator.webkitConnection || {type: 'unknown'}
  let obj = {
    iccid: userInfo.iccid || data.content || '', // 卡iccid
    sim: userInfo.sim || data.content ||  '', // 卡sim
		virtualId: userInfo.virtualId || data.content ||  '', //充值卡号
		cardType: userInfo.cardType || '', // 卡片类型
    openId: userOpenId.openId || '', // 用户openId
    mobile: userInfo.mobile || '', // 手机号
    lastAgentId: userInfo.lastAgentId || '', // 末级代理ID
    agentPath: userInfo.agentPath || '', // 代理路径
    browser: isDevice(), // 浏览器
    netEnv: connectionObj.effectiveType || connectionObj.type,  // 网络环境
    deviceModel: model, // 设备型号
    traceId: sessionStorage.getItem('iotVisitorId') || '' , // traceId(会话标识)  
    beforeRoute: sessionStorage.getItem('beforeRoute') || '', // 上一页面
  }
  if(data.no_params){
    delete obj.iccid
    delete obj.sim
    delete obj.virtualId
  }
  if(data){
    obj = Object.assign({},obj,data)
  }
  return obj;
}

// 发起上报调用接口
export const trackSubmit = async (params=null)=>{
  if(['YuYin','AnFang'].includes(getStore({ name: "themeName" }))){
    await getVisitorId();
    let url = sessionStorage.getItem('trackUrl') || ''
		if(!url){
			url = await getTrackUrl();
			sessionStorage.setItem('trackUrl',url)
		}
		params = handleTrackParams(params)
		console.log(params)
		console.log('获取参数----发起上报')
		axios.post(url,params).then((res)=>{
			if(res.status == 200){
				console.info('上报成功')
			}else {
				console.error('上报失败')
			} 
		})
  }
}

// 全局监听点击事件
export const ListenerFn = ()=>{
  window.addEventListener('click',(e)=>{
    if(e.target){
      let el = e.target
      let clickParams = ''
      if(el.dataset && el.dataset.click){
        clickParams = el.dataset.click
      }else if(el.parentNode && el.parentNode.dataset.click){
        clickParams = el.parentNode.dataset.click
      }else {
        clickParams = ''
      }
      if(clickParams){
        trackSubmit(handleJson(2,clickParams))
      }
    }
  })
}
